<template>
  <div class="content-container flex-row pb-0 pt-20">
    <TopLevelLeftNav />
    <div class="page">
      <div class="subpage-banner"></div>
      <div class="subpage-content">
        <section>
          <div class="content-band">
            <div class="pagehead-title">
              <h1>Experience Pillars</h1>
              <div class="pagehead-link">
                <a href=""
                  ><img src="/html-components/images/svg/link.svg" alt=""
                /></a>
              </div>
            </div>
            <p>
              The InnovateOhio Platform supports agencies, boards, and
              commissions working to ensure that state resources and information
              are accessible to every Ohioan. All Ohio digital touchpoints are
              created to align with the following pillars.
            </p>
          </div>
        </section>

        <div class="divider max-w1200"></div>

        <div class="flex-sidebar flex-wrap">
          <div class="container max-w1200">
            <section id="user-centered">
              <div class="content-band">
                <h3>User Centered</h3>
                <p class="font-red semibold">
                  Prioritizing the User Experience
                </p>
                <ul class="bullet-red">
                  <li>
                    Creating digital frameworks that focus on user needs and
                    actions, not agency tables of organization.
                  </li>
                  <li>
                    Using research and design to validate solutions that satisfy
                    end-user needs.
                  </li>
                  <li>
                    Implementing data-driven discovery to gain better
                    understanding of end-users and the user journey.
                  </li>
                </ul>
              </div>
            </section>

            <section id="curated-content">
              <div class="content-band mt-0">
                <h3>Curated Content</h3>
                <p class="font-red semibold">Creating Consistent Content</p>
                <ul class="bullet-red">
                  <li>
                    Providing a single source of truth for content to address
                    multiple audiences and never duplicating content.
                  </li>
                  <li>
                    Writing in plain-language for the widest possible audience,
                    scrubbed of agency jargon, acronyms, abbreviations and
                    technical terminology.
                  </li>
                  <li>
                    Presenting content at the proper level of fidelity during
                    design and development, ensuring high-fidelity content is in
                    place as the projects near completion.
                  </li>
                </ul>
              </div>
            </section>

            <section id="inclusive-design">
              <div class="content-band mt-0">
                <h3>Inclusive Design</h3>
                <p class="font-red semibold">Ensuring Accessibility</p>
                <ul class="bullet-red">
                  <li>
                    Using consistent, predictable patterns across all sites,
                    focusing on audiences with the greatest need for
                    information, the least comfort with technology and fewer
                    alternative resources.
                  </li>
                  <li>
                    Establishing a shared understanding among content authors of
                    the platform’s built-in accessibility features and the
                    importance of maintaining WCAG 2.0 A and AA compliant.
                  </li>
                  <li>
                    Prioritizing design for mobile devices, knowing the
                    importance of mobile access to users.
                  </li>
                </ul>
              </div>
            </section>
          </div>

          <!-- <div class="container max-w200 p-20">
            <nav class="content-menu">
              <ol class="content-menu-list">
                <li class=""><a href="#user-centered">User Centered</a></li>
                <li class=""><a href="#curated-content">Curated Content</a></li>
                <li class="">
                  <a href="#inclusive-design">Inclusive Design</a>
                </li>
              </ol>
            </nav>
          </div> -->
        </div>
      </div>

      <SubFooter />
    </div>
  </div>
</template>

<script>
import TopLevelLeftNav from "../../components/TopLevelLeftNav";
import SubFooter from "../../components/SubFooter";
export default {
  name: "ExperiencePillars",
  components: {
    TopLevelLeftNav,
    SubFooter,
  },
};
</script>
